.c_button__container + .c_button__container {
  margin-top: 1rem;
}

.c_button {
  border: 1px solid $white;
  border-image: url('/assets/img/border.svg') 25% / 9.6px stretch;
  border-image-outset: 4px;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 400;
  opacity: 0.8;
  padding: 0.75rem 1rem 0.5rem 1rem;
  text-align: center;
  text-transform: uppercase;
  transition: opacity ease 250ms;
}

.c_button:hover {
  opacity: 1;
  text-decoration: none;
}

.p_index__section {
  align-items: center;
  color: $white;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  font-size: 1.1em;
  justify-content: center;
  padding: 2rem;
  position: relative;
  text-align: center;

  @media (min-width: $tablet) {
    font-size: 1.25em;
    padding: 3rem;
  }
}

.p_index__section--parallax {
  position: relative;
  z-index: 0;
}

.p_index__section--parallax::before {
  background: rgba($black, 0.1);
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.p_index__logo__content {
  @extend %svg-shadow;
  width: 9rem * $x-ratio;
  height: 9rem;
}

.p_index__logo__subtitle {
  @extend %text-shadow;
  display: block;
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 0.5em;
  margin: 1em -0.5rem 0 0;
  text-transform: uppercase;
}

.p_index__message {
  font-size: 1.2em;
  margin: 2rem 0;
  max-width: $max-width / 5 * 3;

  @media (min-width: $tablet) {
    margin: 3rem 0;
  }
}

.p_index__section--parallax .p_index__message {
  @extend %text-shadow;
}

.p_index__message p {
  margin: 1rem 0;
}

.p_index__message p:first-child {
  margin-top: 0;
}

.p_index__message p:last-child {
  margin-bottom: 0;
}

.p_index__section .c_button {
  @extend %svg-shadow;
}

/* Welcome */

.p_index__welcome {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}

.p_index__welcome .p_index__logo__content {
  margin: auto 0;
  width: 10rem * $x-ratio;
  height: 10rem;

  @media (min-width: $tablet) {
    width: 13rem * $x-ratio;
    height: 13rem;
  }
}

.p_index__welcome__nextSection {
  @extend %svg-shadow;
  margin: 2rem 0 -1rem;

  @media (min-width: $tablet) {
    margin: 1rem 0 0;
  }
}

.p_index__welcome__nextSection {
  opacity: 0.8;
  transition: opacity ease 250ms;
}

.p_index__welcome__nextSection:hover {
  opacity: 1;
}

.p_index__welcome__nextSection__icon {
  width: 4rem;
  height: 4rem;
}

/* Greetings */

.p_index__greetings {
  background: rgba($black, 0.9);
}

/* Awards */

.p_index__awards .p_index__message {
  color: rgba($black, 0.9);
}

.p_index__awards__images {
  margin: -1rem 0;
  max-width: $max-width;

  @media (min-width: $smartphone-landscape) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -1rem;
  }
}

.p_index__awards__image {
  display: block;
  margin: 1rem auto;
  max-width: 100%;
  max-height: 6rem;

  @media (min-width: $smartphone-landscape) {
    margin: 1rem;
  }

  @media (min-width: $tablet-landscape) {
    max-height: 8rem;
  }
}

/* Audio toggle */

.p_index__audio__toggle {
  @extend %svg-shadow;
  color: rgba($white, 0.8);
  padding: 1rem;
  perspective: 5rem;
  position: absolute;
  transform: translate3d(0, 0, 0);
  transition: color ease 250ms;
  top: 0;
  right: 0;
  width: 5rem;
  height: 5rem;
}

.p_index__audio__toggle:hover {
  color: $white;
}

.p_index__audio__animation {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform ease 500ms;
}

.p_index__audio__on,
.p_index__audio__off {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p_index__audio__off {
  transform: rotateY(0deg);
}

.p_index__audio__toggle--active .p_index__audio__animation,
.p_index__audio__on {
  transform: rotateY(180deg);
}

.c_form {
  background: rgba($black, 0.9);
  color: $white;
  overflow-x: hidden;
  padding: 2rem;
  text-align: center;
  width: 100vw;
  height: 100%;
}

.c_form__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: $max-width / 5 * 3;
  width: 100%;
  min-height: 100%;
}

/* Elements */

.c_form .c_return,
.c_form__title {
  margin-bottom: 2rem;
}

.c_form__description {
  margin-top: 2rem;
}

/* Content */

.c_form__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: left;
  width: 100%;
}

.c_form_field {
  margin-top: 1rem;
  width: 100%;
}

.c_form__content > .c_form_field:first-child {
  margin-top: 0;
}

.c_form__input {
  background: none;
  border: 0;
  border-bottom: 1px solid rgba($white, 0.8);
  display: block;
  transition: border-color ease 250ms;
  width: 100%;
}

.c_form__input:focus {
  border-color: $white;
}

.c_form__label {
  font-size: 0.9rem;
  color: rgba($white, 0.8);
  transition: color ease 250ms;
}

.c_form__input:focus + .c_form__label {
  color: $white;
}

.c_form__content .c_button {
  margin-top: 2rem;
}

/* Group */

.c_form_group {
  align-self: stretch;
  display: flex;
  margin: 0 -0.5rem;
}

.c_form_group .c_form_field {
  margin: 1rem 0.5rem 0;
}

/* Textarea */

.c_form_textarea {
  resize: none;
}

/* Error */

.c_form__error {
  color: red;
  margin-top: 0.5rem;
}

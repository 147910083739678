.c_bgVideo {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.c_bgVideo__content {
  object-fit: cover;
  font-family: 'object-fit: cover;';
  position: relative;
  width: 100%;
  height: 100%;
}

.c_bgVideo__image {
  object-fit: cover;
  font-family: 'object-fit: cover;';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c_footer {
  background: rgba($black, 0.9);
  color: $white;
  padding: 1rem;
}

.c_footer__container {
  max-width: $max-width;

  @media (min-width: $tablet) {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 1rem;
  }
}

.c_footer__logo,
.c_footer__nav,
.c_footer__social,
.c_footer__contact {
  flex: 0 0 auto;
}

.c_footer__logo {
  display: block;
  margin: 0 auto 2rem;
  width: 7rem * $x-ratio;
  height: 7rem;

  @media (min-width: $tablet) {
    margin: 0;
  }
}

.c_footer__nav {
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet) {
    align-items: flex-start;
  }
}

.c_footer__nav__item + .c_footer__nav__item {
  margin-top: 0.5rem;
}

.c_footer__social {
  display: flex;
  justify-content: center;
  margin: 2rem 0;

  @media (min-width: $tablet) {
    margin: 0;
  }
}

.c_footer__social__link + .c_footer__social__link {
  margin-left: 2rem;

  @media (min-width: $tablet) {
    margin-left: 1rem;
  }
}

.c_footer__social__icon {
  width: 2rem;
  height: 2rem;

  @media (min-width: $tablet) {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.c_footer__address,
.c_footer__phone {
  text-align: center;

  @media (min-width: $tablet) {
    text-align: left;
  }
}

.c_footer__address {
  margin: 2rem 0;

  @media (min-width: $tablet) {
    margin: 1rem 0 0;
  }
}

.c_footer__copyright {
  text-align: center;
}

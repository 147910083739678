.c_showcase {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.c_showcase__item {
  align-items: flex-end;
  background: rgba($black, 0);
  box-shadow: inset 0 0 1rem rgba($black, 0.2);
  color: $white;
  cursor: pointer;
  display: flex;
  flex: 0 0 100%;
  height: 40%;
  justify-content: flex-end;
  opacity: 1;
  padding: 1rem;
  position: relative;
  text-align: right;
  transition: box-shadow ease 250ms, background ease 250ms;

  @media (orientation: landscape) {
    flex: 0 0 50%;
    height: 60%;
  }

  @media (min-width: $tablet) {
    padding: 2rem;
  }
}

.c_showcase__item:hover {
  background: rgba($black, 0.4);
  box-shadow: inset 0 0 1rem rgba($black, 0.4);
  color: $white !important;
}

.c_showcase__title {
  @extend %text-shadow;
  hyphens: auto;
  font-size: 2em;
  max-width: 80%;
  overflow-wrap: break-word;

  @media (min-width: $tablet) {
    font-size: 2.5em;
  }
}

.c_carousel {
  position: fixed;
  width: 100%;
  height: 100%;
}

.c_carousel .c_bgImage,
.c_carousel .c_bgVideo {
  position: relative;
  width: 100vw;
  height: 100vh;
}

/* Navigation */

.c_carousel_navigation {
  @extend %svg-shadow;
  bottom: 0;
  color: $white;
  display: flex;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  transition: width ease 250ms;
  width: 50%;

  @media (min-width: $tablet) {
    width: 60%;
  }

  @media (orientation: portrait) {
    bottom: 20%;
    width: 100%;
  }

  &--expanded {
    width: 100%;
  }
}

.c_dialog.c_carousel__dialog {
  padding-bottom: 5rem;
  pointer-events: none;
  width: 50%;

  @media (min-width: $tablet) {
    width: 60%;
  }

  @media (orientation: portrait) {
    bottom: 20%;
    width: 100%;
  }
}

.c_carousel_navigation__button {
  bottom: 0;
  margin: 1rem;
  opacity: 0.8;
  pointer-events: all;
  transition: opacity ease 250ms;
}

.c_carousel_navigation__button:hover {
  opacity: 1;
}

.c_carousel_navigation__prev {
  margin-right: auto;
}

.c_carousel_navigation__next {
  margin-left: auto;
}

.c_carousel_navigation__icon {
  width: 3rem;
  height: 3rem;
}

/* Video control */

.c_carousel_navigation__video:not(.c_carousel_navigation__video--enabled) {
  opacity: 0;
  pointer-events: none;
}

.c_carousel_navigation__control {
  display: block;
  position: relative;
  width: 3rem;
  height: 3rem;
  transform-style: preserve-3d;
  transition: transform ease 500ms;
}

.c_carousel_navigation__play,
.c_carousel_navigation__pause {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c_carousel_navigation__play {
  transform: rotateY(0deg);
}

.c_carousel_navigation__pause {
  transform: rotateY(180deg);
}

.c_carousel_navigation__video--active .c_carousel_navigation__control {
  transform: rotateY(180deg);
}

.c_map {
  width: 50%;
  height: 100%;

  @media (min-width: $tablet) {
    width: 60%;
  }

  @media (orientation: portrait) {
    height: 80%;
    width: 100%;
  }
}

.c_dialog {
  bottom: 0;
  left: 0;
  cursor: default;
  display: flex;
  justify-content: center;
  opacity: 0;
  padding: 1rem;
  pointer-events: none;
  position: fixed;
  transform: translate3d(0, 100%, 0);
  transition: opacity ease 250ms, transform ease 250ms;
  width: 100%;
  z-index: 3;
}

.c_dialog--active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.c_dialog__container {
  background: rgba($black, 0.8);
  color: $white;
  padding: 1rem;
  pointer-events: all;
  text-align: center;
}

.c_dialog__icon {
  width: 1.5em;
  height: 1.5em;
  margin: -0.25em 0.5rem 0 0;
}

.c_dialog__options {
  display: block;
  margin-top: 1rem;
}

.c_dialog .c_button + .c_button {
  margin-left: 1.5rem;
}

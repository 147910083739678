.c_menu {
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.c_menu--active {
  pointer-events: all;
}

/* Background */

.c_menu__background {
  background: rgba($black, 0);
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  transition: background ease 500ms;
  width: 100%;
  height: 100%;
}

.c_menu--active .c_menu__background {
  background: rgba($black, 0.8);
}

/* Button */

.c_menu__button {
  background: rgba($black, 0.8);
  color: rgba($white, 0.8);
  margin-top: -2rem;
  padding: 0.75rem;
  perspective: 4rem;
  pointer-events: all;
  position: absolute;
  transform: translate3d(0, 0, 0);
  transition: color ease 250ms;
  top: 50%;
  width: 4rem;
  height: 4rem;

  @media(min-width: $tablet) {
    margin-top: -2.5rem;
    padding: 1rem;
    perspective: 5rem;
    width: 5rem;
    height: 5rem;
  }
}

.c_menu__button:hover {
  color: $white;
}

.c_menu__animation {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform ease 500ms;
}

.c_menu__open,
.c_menu__close {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c_menu__open {
  transform: rotateY(0deg);
}

.c_menu__close {
  transform: rotateY(180deg);
}

.c_menu--active .c_menu__animation {
  transform: rotateY(180deg);
}

/* Container */

.c_menu__container {
  background: rgba($black, 0.8);
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  padding: 2rem 0 2rem 4rem;
  position: absolute;
  top: 0;
  transform: translate3d(-100%, 0, 0);
  transition: transform ease 500ms 1ms;

  @media(min-width: $tablet) {
    padding-left: 5rem;
  }
}

.c_menu--active .c_menu__container {
  transform: translate3d(0, 0, 0);
}

/* Content */

.c_menu__content {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  font-weight: 400;
  justify-content: center;
  min-height: 100%;
  text-transform: uppercase;
}

/* Menu items */

.c_menu__item {
  color: rgba($white, 0.8);
  display: flex;
  padding: 0.5rem 1.5rem;
  transition: color ease 250ms;

  @media (min-width: $smartphone-small) {
    font-size: 1.5rem;
    padding: 0.75rem 1.5rem;
  }

  @media (min-width: $tablet) {
    padding: 0.75rem 3rem;
  }
}

.c_menu__item:hover {
  color: $white;
  text-decoration: none;
}

.c_menu__item .c_menu__icon {
  width: 1em;
  height: 1em;
  margin: 0 0.75rem 0 0;
}

/* Lang */

.c_menu__lang {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate3d(100%, 0, 0);
  transition: transform ease 500ms;

  @media (min-width: $smartphone-landscape) {
    flex-direction: row;
  }

  @media (min-width: $tablet) {
    padding: 2rem;
  }
}

.c_menu--active .c_menu__lang {
  transform: translate3d(0, 0, 0);
}

.c_menu__link {
  opacity: 0.8;
  transition: opacity ease 250ms;
}

.c_menu__link + .c_menu__link {
  margin: 1rem 0 0;

  @media (min-width: $smartphone-landscape) {
    margin: 0 0 0 1rem;
  }

  @media (min-width: $tablet) {
    margin: 0 0 0 1.5rem;
  }
}

.c_menu__link:hover {
  opacity: 1;
  text-decoration: none;
}

.c_menu__lang .c_menu__icon {
  width: 1.75rem;
  height: 1.75rem;

  @media (min-width: $tablet) {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.c_sideContent {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media (orientation: portrait) {
    overflow: auto;
    position: static;
  }
}

.c_sideContent__container {
  background: rgba($black, 0.6);
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: all;
  position: absolute;
  text-align: right;
  transform: translate3d(0, 0, 0);
  transition: transform ease 250ms;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;

  @media (min-width: $tablet) {
    width: 40%;
  }

  @media (orientation: portrait) {
    min-height: 20%;
    top: 80%;
    bottom: auto;
    width: 100%;
  }
}

.c_sideContent--hidden .c_sideContent__container {
  transform: translate3d(100%, 0, 0);
}

.c_sideContent__info {
  overflow: auto;
  padding: 2rem;

  @media (orientation: landscape) {
    max-height: 100%;
  }
}

.c_sideContent__info .c_return,
.c_sideContent__title,
.c_sideContent__subtitle {
  margin-bottom: 2rem;
}

.c_sideContent__subtitle {
  font-size: 1.1em;
  margin-top: -2.5rem;
}

.c_sideContent__content p {
  white-space: pre-wrap;
}

.c_sideContent__content p + p {
  margin-top: 1rem;
}

.c_sideContent .c_button__container + p,
.c_sideContent p + .c_button__container {
  margin-top: 2rem;
}

/* Toggle */

.c_sideContent__toggle {
  @extend %svg-shadow;
  color: rgba($white, 0.8);
  padding: 1rem;
  perspective: 5rem;
  position: absolute;
  transform: translate3d(0, 0, 0);
  transition: color ease 250ms;
  top: 0;
  left: -5rem;
  width: 5rem;
  height: 5rem;
}

.c_sideContent__toggle:hover {
  color: $white;
}

.c_sideContent__animation {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform ease 500ms;
}

.c_sideContent__expand,
.c_sideContent__collapse {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c_sideContent__expand {
  transform: rotateY(0deg);
}

.c_sideContent__collapse,
.c_sideContent--hidden .c_sideContent__animation {
  transform: rotateY(180deg);
}

@import "C:/maitei/assets/css/variables/colors.scss";
@import "C:/maitei/assets/css/variables/fonts.scss";
@import "C:/maitei/assets/css/variables/logo.scss";
@import "C:/maitei/assets/css/variables/shadows.scss";
@import "C:/maitei/assets/css/variables/sizes.scss";
@import "C:/maitei/assets/css/variables/viewports.scss";


*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
main {
  height: 100%;
}

html {
	text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
	color: $black;
  font: 300 16px/1.2 $body-font;
  margin: 0;
	-webkit-font-smoothing: antialiased;
}

header,
main,
footer,
address {
  display: block;
}

address {
  font-style: normal;
}

h1,
h2,
h3,
p,
ul {
  margin: 0;
  padding: 0;
}

h1 {
  font: 400 2.5em $header-font;
  line-height: 1;
  margin: 0;
  overflow-wrap: break-word;
  text-transform: lowercase;

  @media (min-width: $tablet) {
    font-size: 3em;
  }
}

h2,
h3 {
  font-weight: 400;
  letter-spacing: -0.05rem;
	text-transform: uppercase;
}

p {
	line-height: 1.5;
}

li {
  display: block;
  padding-left: 1.1em;
  position: relative;
}

li::before {
  content: '•';
  position: absolute;
  margin: 0.1em 0 0 -1.1em;
}

li li::before {
  content: '-';
}

a,
area,
button,
[role="button"],
input:not([type=range]),
label,
select,
summary,
textarea {
  color: inherit;
  touch-action: manipulation;
}

a {
  color: inherit;
  opacity: 0.8;
  text-decoration: none;
  transition: opacity ease 250ms;
}

a:hover {
  color: inherit;
  opacity: 1;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  overflow: visible;
  padding: 0;
  -webkit-font-smoothing: inherit;
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input,
textarea {
  color: inherit;
  font: inherit;
}

:focus {
  outline: 0;
}

img,
svg {
  display: inline-block;
  vertical-align: middle;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
}

svg {
  fill: currentColor;
}

@import "C:/maitei/assets/css/components/accordion.scss";
@import "C:/maitei/assets/css/components/amenities.scss";
@import "C:/maitei/assets/css/components/article.scss";
@import "C:/maitei/assets/css/components/bg-image.scss";
@import "C:/maitei/assets/css/components/bg-video.scss";
@import "C:/maitei/assets/css/components/button.scss";
@import "C:/maitei/assets/css/components/carousel.scss";
@import "C:/maitei/assets/css/components/dialog.scss";
@import "C:/maitei/assets/css/components/flatpickr.scss";
@import "C:/maitei/assets/css/components/footer.scss";
@import "C:/maitei/assets/css/components/form.scss";
@import "C:/maitei/assets/css/components/map.scss";
@import "C:/maitei/assets/css/components/menu.scss";
@import "C:/maitei/assets/css/components/modal.scss";
@import "C:/maitei/assets/css/components/not-found.scss";
@import "C:/maitei/assets/css/components/return.scss";
@import "C:/maitei/assets/css/components/showcase.scss";
@import "C:/maitei/assets/css/components/side-content.scss";

@import "C:/maitei/assets/css/pages/contact.scss";
@import "C:/maitei/assets/css/pages/index.scss";
@import "C:/maitei/assets/css/pages/reservations.scss";


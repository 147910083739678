.c_modal {
  background: rgba($black, 0);
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transition: background ease 250ms;
  width: 100vw;
  height: 100vh;
  z-index: 20;
}

.c_modal--active {
  background: rgba($black, 0.7);
}

.c_modal--lock-body {
  overflow: hidden;
}

.c_modal__container {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  opacity: 0;
  transition: opacity ease 250ms;
  width: 100vw;
}

.c_modal--active .c_modal__container {
  opacity: 1;
}

.c_modal__content {
  background: rgba($black, 0.8);
  color: $white;
  padding: 2rem;
  text-align: center;

  @media (min-width: $tablet) {
    max-width: 50vw;
  }
}

.c_modal__container .c_button {
  margin-top: 2rem;
}

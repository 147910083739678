.c_notFound {
  align-items: center;
  background: rgba($black, 0.9);
  color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
}

.c_notFound__title {
  font-size: 9em;
  margin-bottom: -2rem;

  @media (min-width: $smartphone-landscape) {
    font-size: 12em;
    margin-bottom: -2.5rem;
  }
}

.c_notFound__subtitle {
  font-size: 1.2em;

  @media (min-width: $smartphone-landscape) {
    font-size: 1.5em;
  }
}

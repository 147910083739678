.c_return {
  color: inherit;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 400;
  opacity: 0.8;
  text-transform: uppercase;
  transition: opacity ease 250ms;
}

.c_return:hover {
  color: inherit;
  opacity: 1;
  text-decoration: none;
}

.c_return--hidden {
  display: none;
}

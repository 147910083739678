$gray90: #1a1a1a;

.c_accordion {
  background: $gray90;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  width: 100%;
  height: 100%;

  @media (orientation: landscape) {
    flex-direction: row;
  }
}

.c_accordion__item {
  align-items: flex-end;
  background: $gray90;
  color: $white;
  cursor: pointer;
  display: flex;
  flex: 0 0 60%;
  justify-content: flex-end;
  padding: 1rem;
  position: relative;
  text-align: right;
  transition: transform ease 500ms;

  @media (min-width: $tablet) {
    padding: 2rem;
  }
}

.c_accordion__item .c_bgImage {
  object-position: 75% 50%;
}

.c_accordion__title {
  @extend %text-shadow;
  hyphens: auto;
  font-size: 2em;
  overflow-wrap: break-word;

  @media (min-width: $tablet) {
    font-size: 3em;
  }
}

.c_accordion__subtitle {
  @extend %text-shadow;
  font-size: 0.9em;
  margin-top: -0.5em;

  @media (min-width: $tablet) {
    font-size: 1em;
  }
}

.c_accordion__link {
  align-items: center;
  background: rgba($black, 0.2);
  box-shadow: inset 0 0 1rem rgba($black, 0.2), 0 0 1rem rgba($black, 0.8);
  display: flex;
  font-size: 1.1em;
  justify-content: center;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (min-width: $tablet) {
    font-size: 1.25em;
  }
}

.c_accordion--enhanced .c_accordion__link {
  background: rgba($black, 0.4);
  transition: background ease 500ms;
}

.c_accordion--enhanced .c_accordion__item--active .c_accordion__link {
  background: rgba($black, 0.2);
}

.c_accordion__link .c_button {
  @extend %svg-shadow;
}

.c_accordion__link:hover .c_button {
  opacity: 1;
}

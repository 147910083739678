.c_article {
  color: rgba($black, 0.9);
  padding: 2rem;
}

.c_article__container {
  margin: 0 auto;
  max-width: $max-width / 5 * 3;
}

.c_article .c_return,
.c_article__title {
  margin-bottom: 2rem;
}

.c_article__content {
  font-size: 1.2em;
}

.c_article__content h2 {
  font-size: 1.1em;
  margin-bottom: 1rem;
}

.c_article__content h3 {
  font-size: 1em;
  margin: 2rem 0 1rem;
}

.c_article__content p + h2 {
  margin-top: 2rem;
}

.c_article__content p {
  white-space: pre-wrap;
}

.c_article__content p + p,
.c_article__content ul + p,
.c_article__content ul + ul,
.c_article__content p + ul {
  margin-top: 1rem;
}

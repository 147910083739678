.c_amenities {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 1rem -0.75rem 0 -1rem;
}

.c_amenities__item {
  font-size: 0.75em;
  hyphens: auto;
  margin: 1rem 0 0 1rem;
  overflow-wrap: break-word;
  width: 4rem;
  text-align: center;
}

.c_amenities__icon {
  display: block;
  margin: 0 auto 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
}
